.person {
  margin: 10px;
  padding-left: 5px;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  background: #53354a;
  color: white;
  height: 50px;
  cursor: move;
  display: flex;
  align-items: center;
}

.image {
  width: 40px;
  height: 40px;
}

.name {
  padding: 0px 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
}

.modal {
  outline: none;
  position: relative;
  width: 500px;
  height: 500px;
  box-shadow: 6px 6px 6px 1px rgba(0, 0, 0, 0.2);
}

.modal > img {
  width: 100%;
  height: 100%;
}
