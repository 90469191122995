#positions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#headerContainer {
  top: 0px;
  position: sticky;
  border: 1px solid;
}

#header {
  top: 0px;
  position: sticky;
  background-color: #f4f7f7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#header h1 {
  font-size: 1.3em;
  display: block;
  width: 100%;
}

#questionContainer {
  width: 65vw;
  margin: 20px auto;
  background-color: #f4f7f7;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}

#submitContainer {
  background: #f4f7f7;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

#submit {
  font-size: 1em;
  padding: 5px;
  margin: 10px;
}

#spinner {
  padding: 11px;
  margin: 10px;
}
