.position {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.index {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  margin-left: auto;
  margin-right: 10px;
  width: 60%;
  resize: none;
}
