.textpanel {
  width: 65vw;
  background-color: #f4f7f7;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container button {
  font-size: 1.2em;
}
