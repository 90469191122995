body {
  margin: 0px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: lighter;
  color: #53354a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  padding: 0px;
  background: radial-gradient(#2b2e4a, #53354a);
}
